body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: '#e9e9e9'
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  box-shadow: 0 0 0 30px #ffffff inset !important;
}


.has-text-align-left {
	text-align: left;
}

.has-text-align-center {
	text-align: center;
}

.has-text-align-right {
	text-align: right;
}

